import React, { useState } from "react";
import "./emailSender.scss";
import { Link } from "react-router-dom";
import mainLogo from "../../assets/header/Dark-Logo.svg";
import locateSvg from "../../assets/header/settings.svg";
import { AiFillSetting } from "react-icons/ai";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { Form, Input, Select, Button, Row, Col, Dropdown, Space } from "antd";
import { MailOutlined } from "@ant-design/icons";
import Notify from "../common/Notify/notify";
import { HiMenu } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
import { FaUsers } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { MdPriceChange } from "react-icons/md";
import { useAppContextInput } from "../../App";

const EmailSender = (props) => {
  const { t } = useTranslation();
  const cookies = new Cookies();
  const role = cookies.get("role");
  const lang = cookies.get("lang");
  const { Option } = Select;
  const { callEmailSender, callLanguages } = props;
  const WebsiteData = [
    { websiteName: "Tracelo", value: "Tracelo.com" },
    { websiteName: "FindFast", value: "Findfast.io" },
    { websiteName: "Location-Tool", value: "Location-tool.com" },
  ];
  const template = [
    { templateName: "account created", templateValue: "account_created" },
    { templateName: "new password", templateValue: "new_password" },
    { templateName: "account canceled", templateValue: "account_canceled" },
  ];

  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onFinish = async (values) => {
    setIsSubmitting(true);
    try {
      const result = await callEmailSender(values);
      if (result.type === "emailSender/fulfilled") {
        Notify("success", "Email sent successfully", "");
        form.resetFields();
      } else {
        Notify("error", result.error.message, "");
      }
    } catch (error) {
      Notify("error", "Email send failed", "");
    } finally {
      setIsSubmitting(false);
    }
  };

  const onReset = () => {
    form.resetFields();
  };
  // hum-burger-menu
  const { handleLogout } = useAppContextInput();
  const items = [
    {
      label: (
        <Link to={`/${lang}/dash`}>
          <img src={locateSvg} alt="" width={20} height={20} />
          <span>{t("DASHBOARD")}</span>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to={`/${lang}/settings`}>
          <AiFillSetting />
          <span>{t("FOOTER_ACCOUNT")}</span>
        </Link>
      ),
      key: "1",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/email-test`} className="active">
          <MailOutlined />
          <span>{t("TEST_EMAIL")}</span>
        </Link>
      ),
      key: "2",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/currency`}>
          <MdPriceChange />
          <span>{t("CURRENCIES")}</span>
        </Link>
      ),
      key: "3",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/admin`}>
          <RiAdminFill />
          <span>{t("ADMINS")}</span>
        </Link>
      ),
      key: "4",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/users`}>
          <FaUsers />
          <span>{t("DASHBOARD_HEADER_USER")}</span>
        </Link>
      ),
      key: "5",
    },
    {
      label: (
        <Link to={`#`} onClick={handleLogout}>
          <TbLogout />
          <span>{t("LOGOUT")}</span>
        </Link>
      ),
      key: "6",
    },
  ];
  return (
    <div className="dashnoard-main-section">
      <div className="container">
        <div className="dashboard-header">
          <div className="main-logo-img">
            <Link to={`/${lang}/dash`}>
              <img src={mainLogo} alt="" />
            </Link>
          </div>
          <div className="hum-burger-menu">
            <Dropdown
              menu={{ items }}
              trigger={["click"]}
              placement={lang === "ar" || lang === "he" ? "bottomLeft" : "bottomRight"}
              overlayClassName="dashboard-header-menu-mobile"
            >
              <span onClick={(e) => e.preventDefault()}>
                <Space>
                  <HiMenu />
                </Space>
              </span>
            </Dropdown>
          </div>
        </div>
        <div className="form-container">
          <Form
            form={form}
            name="myForm"
            onFinish={onFinish}
            layout="vertical"
            className="custom-form"
          >
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    prefix={<MailOutlined />}
                    placeholder="Enter your email"
                    className="custom-input"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="website"
                  label="Website"
                  rules={[
                    { required: true, message: "Please select a website!" },
                  ]}
                >
                  <Select
                    placeholder="Select a website"
                    className="custom-select"
                  >
                    {WebsiteData.map((site) => (
                      <Option key={site.value} value={site.value}>
                        {site.websiteName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="language"
                  label="Language"
                  rules={[
                    { required: true, message: "Please select a language!" },
                  ]}
                >
                  <Select
                    placeholder="Select a language"
                    className="custom-select"
                  >
                    {callLanguages.map((lang) => (
                      <Option key={lang} value={lang}>
                        {lang}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item
                  name="template"
                  label="Email Template"
                  rules={[
                    {
                      required: true,
                      message: "Please select an email template!",
                    },
                  ]}
                >
                  <Select
                    placeholder="Select an email template"
                    className="custom-select"
                  >
                    {template.map((template) => (
                      <Option
                        key={template.templateValue}
                        value={template.templateValue}
                      >
                        {template.templateName}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item className="setting-btn">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default EmailSender;
