import React, { useState, useEffect, useRef } from "react";
import "./dashboard.scss";
import mainLogo from "../../assets/header/Dark-Logo.svg";
import PhoneInput from "react-phone-input-2";
import { AiOutlineSearch, AiFillSetting } from "react-icons/ai";
import { MdPriceChange } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { Dropdown, Form, Input, Modal, Select, Space, Spin } from "antd";
import { googleAPIKey } from "../../environment";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  GoogleMap,
  useLoadScript,
  MarkerF,
  InfoWindowF,
} from "@react-google-maps/api";
import { Cookies, useCookies } from "react-cookie";
import { RiAdminFill } from "react-icons/ri";
import parsePhoneNumber, {
  isValidPhoneNumber,
  parsePhoneNumberFromString,
} from "libphonenumber-js";
import countryList from "react-select-country-list";
import Notify from "../common/Notify/notify";
import ReactivePaymentModal from "./module/reactivePaymentModal";
import UpdatePaymentModal from "./module/updatePaymentModal/updatePaymentModal";
import UnsubscribeModal from "./module/unsubscribeModal";
import { MailOutlined } from "@ant-design/icons";
import { HiMenu } from "react-icons/hi";
import { TbLogout } from "react-icons/tb";
import { useAppContextInput } from "../../App";
import locateSvg from "../../assets/header/settings.svg";
import ReactCountryFlag from "react-country-flag";
import { languages, renderRTLClass } from "../../utils/commonUtils";
import useScrollToTop from "../customHook/useScrollToTop";
import { handleSetNumber, handleSetPhoneNumber } from "../../utils/commonUtils";
// locationListData.data.dailyRequestCount
const DashboardComponent = (props) => {
  const {
    handleOnNumberChange,
    handleSendMessage,
    handleOnReceiverNumberChange,
    locationListData,
    number,
    openLocateModal,
    setOpenLocateModal,
    handleChangeMessage,
    numberError,
    setNumberError,
    countryShortName,
    setCountryCodeinput,
    popNumberError,
    resultError,
    messageError,
    authenticate,
    callupdateSetting,
    receiverNumber,
    updateSetting,
    getSendDetails,
    ipData,
    sendLocation,
    callunsubscribe,
    unsubscribeResult,
    getAuthenticate,
    messageLength,
    phoneRef,
    setPopNumberError,
    setReceiverNumber,
    receiverCountryCode,
    setMessageError,
    setMessage,
    message,
    setMessageLength,
    callUpdateUserLanguage,
  } = props;
  const cookies = new Cookies();
  const role = cookies.get("role");
  const langCheck = authenticate?.authenticateData?.data?.language;
  const [lang, setLang] = useState(langCheck ? langCheck : cookies.get("lang"));
  const [ip, setIp] = useState();
  const { t, i18n } = useTranslation();
  const [unsubscribeModalOpen, setUnsubscribeModalOpen] = useState(false);
  const [unsubscribeSuccessModal, setUnsubscribeSuccessModal] = useState(false);
  const [updatePaymentModalOpen, setUpdatePaymentModalOpen] = useState(false);
  const [step, setStep] = useState(1);
  const [countryCodeinput, setCountryCode] = useState("ca");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const navigate = useNavigate();
  const [showLogo, setShowLogo] = useState("gb");
  const [updatedFlag, setUpdatedFlag] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [locatingPopupCountryCodeinput, setLocatingPopupCountryCode] =
    useState("ca");
  const [dialCode, setDialCode] = useState("+1");
  const [locatingPopupDialCode, setLocatingPopupDialCode] = useState("+1");
  const langCookies = cookies.get("lang");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formatedNumber, setFormatedNumber] = useState("");
  const [locatingPopupPhoneNumber, setLocatingPopupPhoneNumber] = useState("");
  const location = useLocation();
  const [finalFormattedNumber, setFinalFormattedNumber] = useState(
    location.pathname
  );
  const [locatingNumberError, setLocatingNumberError] = useState("");
  const [renderRTL, setRenderRTLClass] = useState(
    ["ar", "he"].includes(window.location.pathname.split("/")[1])
  );
  const [defaultIP, setDefaultIP] = useState("");

  useEffect(() => {
    setRenderRTLClass(
      ["ar", "he"].includes(window.location.pathname.split("/")[1])
    );
  }, [window.location.pathname]);
  const { inputRef, handleLogout } = useAppContextInput();
  useEffect(() => {
    setLang(langCookies);
  }, [langCookies]);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", openLocateModal);
    return () => document.body.classList.remove("no-scroll");
  }, [openLocateModal]);
  useScrollToTop();
  const handleGetSendDetails = async () => {
    const result = await getSendDetails(ip);
    if (result.type === "getSendDetails/fulfilled") {
      let data = result.payload.data;
      setCountryCode(data.country_code.toLowerCase());
      setCountryCodeinput(data.country_code.toLowerCase());
      setLocatingPopupCountryCode(data.country_code.toLowerCase());
      setDialCode(data.country_calling_code);
      setLocatingPopupDialCode(data.country_calling_code);
      setDefaultIP(data.country_code.toLowerCase());
    }
  };
  useEffect(() => {
    if (ip) {
      handleGetSendDetails();
    }
    // eslint-disable-next-line
  }, [ip]);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const countryOptions = countryList().getData();
  const [label, setLabel] = useState(t("POSTAL_CODE"));
  const [numberValidate, setNumberValidate] = useState("");
  const [settingUpdateModal, setSettingUpdateModal] = useState(false);
  const [center, setCenter] = useState({
    lat: 40.7128,
    lng: -73.935242,
  });
  const [inFowindowOpen, setInFowindowOpen] = useState(false);
  const [latLong, setLatLong] = useState([
    {
      latlng: { lat: null, lng: null },
      address: "",
    },
  ]);
  // eslint-disable-next-line
  const [token, setToken, removeToken] = useCookies(["token"]);
  // eslint-disable-next-line
  const [roles, setRole, removeRole] = useCookies(["role"]);
  useEffect(() => {
    if (locationListData && locationListData.data.length > 0) {
      let defaultLatLong = locationListData.data.find((item) => {
        return item.status === true;
      });
      if (defaultLatLong) {
        setLatLong([
          {
            latlng: {
              lat: defaultLatLong.geo.lat,
              lng: defaultLatLong.geo.long,
            },
            address: defaultLatLong.geo.formatted,
          },
        ]);
        setCenter({
          lat: defaultLatLong.geo.lat,
          lng: defaultLatLong.geo.long,
        });
      }
    }
  }, [locationListData]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: googleAPIKey,
  });
  const [error, setError] = useState({
    first_name: "",
    last_name: "",
    address: "",
    country: "",
    city: "",
    zipcode: "",
  });

  const [details, setDetails] = useState({
    first_name: authenticate.authenticateData
      ? authenticate.authenticateData.data.first_name
      : "",
    last_name: authenticate.authenticateData
      ? authenticate.authenticateData.data.last_name
      : "",
    address: authenticate.authenticateData
      ? authenticate.authenticateData.data.address
      : "",
    country: authenticate.authenticateData
      ? authenticate.authenticateData.data.country
      : countryCodeinput.toUpperCase(),
    city: authenticate.authenticateData
      ? authenticate.authenticateData.data.city
      : "",
    zipcode: authenticate.authenticateData
      ? authenticate.authenticateData.data.zipcode
      : 0,
  });

  useEffect(() => {
    document.body.classList.toggle("no-scroll", openLocateModal);
    return () => document.body.classList.remove("no-scroll");
  }, [openLocateModal]);
  useEffect(() => {
    if (ipData && ipData.ip) {
      setIp(ipData.ip);
    }
  }, [ipData]);
  useEffect(() => {
    if (details.country === "PH" || details.country === "US") {
      setLabel(t("ZIP_CODE"));
    } else {
      setLabel(t("POSTAL_CODE"));
    }
  }, [details.country]);

  useEffect(() => {
    let fullNumber;

    if (
      phoneNumber &&
      dialCode === "91" &&
      phoneNumber.length === 14 &&
      phoneNumber.startsWith(dialCode)
    ) {
      // Remove the dial code (91) from the phone number
      fullNumber = "+" + dialCode + phoneNumber.slice(dialCode.length);
    } else {
      // Use the full phone number with the dial code
      fullNumber = "+" + dialCode + phoneNumber;
    }

    const finalNumber = parsePhoneNumber(fullNumber);

    if (finalNumber) {
      setFormatedNumber(finalNumber.formatInternational());
    }
  }, [phoneNumber]);

  useEffect(() => {
    if (number) {
      let fullNumber = "+" + number;
      const phoneNumber = parsePhoneNumber(fullNumber);
      if (phoneNumber) {
        setFormatedNumber(phoneNumber.formatInternational());
      }
    }
  }, [number]);
  useEffect(() => {
    if (searchNumber.D && searchNumber.n) {
      setPhoneNumber(searchNumber.n);
    }
  }, []);
  useEffect(() => {
    if (authenticate.authenticateData) {
      setDetails((prev) => ({
        ...prev,
        first_name: authenticate.authenticateData.data.first_name,
        last_name: authenticate.authenticateData.data.last_name,
        address: authenticate.authenticateData.data.address,
        country: authenticate.authenticateData.data.country
          ? authenticate.authenticateData.data.country
          : countryCodeinput.toUpperCase(),
        city: authenticate.authenticateData.data.city,
        zipcode: authenticate.authenticateData.data.zipcode,
      }));
    }
  }, [authenticate.authenticateData, countryCodeinput]);

  useEffect(() => {
    if (authenticate.authenticateData) {
      let authError = {};
      if (!details.first_name) {
        authError.first_name = "error";
      }
      if (!details.last_name) {
        authError.last_name = "error";
      }
      if (!details.address) {
        authError.address = "error";
      }
      if (!details.country) {
        authError.country = "error";
      }
      if (!details.city) {
        authError.city = "error";
      }
      if (!details.zipcode) {
        authError.zipcode = "error";
      }

      if (Object.keys(authError).length !== 0) {
        setSettingUpdateModal(true);
      }
    }
    // eslint-disable-next-line
  }, [details]);

  const handleOpenLocateModal = () => {
    setNumberError(" ");
    setLocatingNumberError("");
    const parsedNumber = parsePhoneNumberFromString(
      phoneNumber,
      countryCodeinput.replace("+", "").toUpperCase()
    );

    let trimmedNumber = finalFormattedNumber.trim();
    if (trimmedNumber.startsWith(dialCode)) {
      trimmedNumber = trimmedNumber.substring(dialCode.length).trim();
      if (
        trimmedNumber
          .replace(" ", "")
          .replace("-", "")
          .replace("(", "")
          .replace(")", "").length < 10
      ) {
        if (dialCode.length <= 2) {
          trimmedNumber = dialCode + trimmedNumber;
        }
      }
    }
    setFormatedNumber(`+${dialCode} ${trimmedNumber}`);
    if (phoneNumber && phoneNumber !== "0") {
      if (
        parsedNumber &&
        parsedNumber.isValid() &&
        parsedNumber.country === countryCodeinput.replace("+", "").toUpperCase()
      ) {
        if (
          locationListData &&
          locationListData.data &&
          locationListData.data.dailyRequestCount >= 10
        ) {
          setNumberError(
            "Your daily limit has been reached. Please try again later."
          );
        } else {
          setOpenLocateModal(true);
          setLocatingPopupCountryCode(defaultIP);
          setNumberError("");
        }
      } else {
        setNumberValidate(t("ERROR_VALID_NUMBER_DASHBOARD"));
      }
    } else {
      setNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
    }
  };

  const handleCloseLocateModal = () => {
    setLocatingPopupPhoneNumber("");
    setPhoneNumber("");
    setLocatingPopupPhoneNumber(" ");
    setLocatingPopupCountryCode(defaultIP);
    setOpenLocateModal(false);
    navigate(`/${lang}/dash`);
    setMessage(t("SMS_DEFAULT_TEXT"));
    setPopNumberError("");
    setReceiverNumber(countryShortName.toLowerCase());
    setMessageError("");
    setMessageLength(t("SMS_DEFAULT_TEXT").length);
  };
  const handleChangeMap = (lat, lng, address) => {
    setInFowindowOpen(false);
    const alredyLatLong = latLong.find((e) => {
      return e.latlng.lat === lat && e.latlng.lng === lng;
    });
    if (!alredyLatLong) {
      setLatLong([
        ...latLong,
        {
          latlng: { lat: lat, lng: lng },
          address: address,
        },
      ]);
    }

    setCenter({
      lat: lat,
      lng: lng,
    });
  };

  const mapOptions = {
    disableDefaultUI: true, // Disable default map controls
    zoomControl: false, // Disable zoom control
    streetViewControl: false, // Disable street view control
    fullscreenControl: false, // Disable fullscreen control
    mapTypeControl: false, // Disable map type control
    gestureHandling: "greedy",
    styles: [
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [{ color: "#abcdef" }, { visibility: "on" }],
      },
      {
        featureType: "landscape",
        elementType: "all",
        stylers: [{ color: "#f2f2f2" }],
      },
    ],
  };

  const handleChangeDetails = (event) => {
    const { name, value } = event.target;
    const trimmedValue =
      typeof value === "string" ? value.replace(/^\s+/g, "") : value;
    if (trimmedValue.length < 40) {
      setDetails((prev) => ({
        ...prev,
        [name]: trimmedValue,
      }));
    } else if (name === "address") {
      if (trimmedValue.length < 256) {
        setDetails((prev) => ({
          ...prev,
          [name]: trimmedValue,
        }));
      }
    }
    if (trimmedValue) {
      setError((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  const handleChangeCountry = (event) => {
    let selectedValue;
    if (event && event.target) {
      selectedValue = event.target.value;
    } else {
      selectedValue = event;
    }
    setDetails((prev) => ({
      ...prev,
      country: selectedValue,
    }));
    if (selectedValue) {
      setError((prev) => ({
        ...prev,
        country: "",
      }));
    }
  };

  const handleUpdateDetails = async () => {
    // setLoading(true);
    let authError = {};
    if (!details.first_name) {
      authError.first_name = t("BILLING_FIRST_NAME_REQUIRED");
    }
    if (!details.last_name) {
      authError.last_name = t("BILLING_LAST_NAME_REQUIRED");
    }
    if (!details.address) {
      authError.address = t("BILLING_ADDRESS_REQUIRED");
    }
    if (!details.country) {
      authError.country = t("BILLING_COUNTRY_REQUIRED");
    }
    if (!details.city) {
      authError.city = t("BILLING_CITY_REQUIRED");
    }
    if (!details.zipcode) {
      authError.zipcode = t("BILLING_POSTAL_CODE_REQUIRED");
    }
    if (Object.keys(authError).length !== 0) {
      setError(authError);
      return;
    }
    const result = await callupdateSetting(details);
    if (result.type === "updateSetting/fulfilled") {
      Notify("success", t("NOTIFY_UPDATE_SETTINGS"), "");
      setSettingUpdateModal(false);
      getAuthenticate();
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      if (searchNumber.D && searchNumber.n) {
        setOpenLocateModal(true);
        document.getElementById("receiver_number_input")?.focus();
      }
    } else {
      if (result?.payload?.status !== 401) {
        Notify("error", result?.payload?.data?.message, "");
      }
    }
    // setLoading(false);
  };
  // ReActive Payment Flow
  useEffect(() => {
    if (authenticate && authenticate.authenticateData) {
      const pastDuoStatus =
        authenticate.authenticateData.data.subscription.status;
      if (pastDuoStatus === "past_due") {
        setUpdatePaymentModalOpen(true);
      }
    }
  }, [authenticate.authenticateData]);
  // Unsubscribe from ReActive Payment Flow
  const handleNavigateUnsubscribe = () => {
    setUnsubscribeModalOpen(true);
    setStep(1);
    setUpdatePaymentModalOpen(false);
  };
  const handleCloseUnsubscribe = () => {
    setUnsubscribeModalOpen(false);
    setStep(1);
    setUpdatePaymentModalOpen(true);
  };
  const handleUnsubscribe = async () => {
    setStep(1);
    setUpdatePaymentModalOpen(false);
    const userEmail = authenticate.authenticateData.data.email;
    const data = {
      email: userEmail,
    };
    const result = await callunsubscribe(data);
    if (result.type === "unsubscribe/fulfilled") {
      setUnsubscribeModalOpen(false);
      setUnsubscribeSuccessModal(true);
      if (result.payload.data.success) {
        Notify("success", t("NOTIFY_UNSUBSCRIBE_PAGE"), "");
      }
      setTimeout(() => {
        removeToken(["token"]);
        removeRole(["role"]);
        const cookieKeys = Array.isArray(cookies.getAll())
          ? cookies.getAll()
          : Object.keys(cookies.getAll());
        cookieKeys.forEach((key) => {
          cookies.remove(key, { path: "/" });
        });
        navigate("/");
        window.location.reload("/");
      }, 10000);
    } else {
      Notify("error", result.error.message, "");
    }
  };
  const handlePaymentUpdate = async () => {
    setUnsubscribeModalOpen(false);
    setStep(1);
    setUpdatePaymentModalOpen(false);
    setTimeout(() => {
      getAuthenticate();
    }, 1000);
  };
  // hum-burger-menu
  const items = [
    {
      label: (
        <Link to={`/${lang}/dash`} className="active">
          <img src={locateSvg} alt="" width={20} height={20} />
          <span>{t("DASHBOARD")}</span>
        </Link>
      ),
      key: "0",
    },
    {
      label: (
        <Link to={`/${lang}/settings`}>
          <AiFillSetting />
          <span>{t("FOOTER_ACCOUNT")}</span>
        </Link>
      ),
      key: "1",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/email-test`}>
          <MailOutlined />
          <span>{t("TEST_EMAIL")}</span>
        </Link>
      ),
      key: "2",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/currency`}>
          <MdPriceChange />
          <span>{t("CURRENCIES")}</span>
        </Link>
      ),
      key: "3",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/admin`}>
          <RiAdminFill />
          <span>{t("ADMINS")}</span>
        </Link>
      ),
      key: "4",
    },
    role === "admin" && {
      label: (
        <Link to={`/${lang}/users`}>
          <FaUsers />
          <span>{t("DASHBOARD_HEADER_USER")}</span>
        </Link>
      ),
      key: "5",
    },
    {
      label: (
        <Link
          to={`#`}
          onClick={() => {
            handleLogout();
          }}
        >
          <TbLogout />
          <span>{t("LOGOUT")}</span>
        </Link>
      ),
      key: "6",
    },
  ];
  useEffect(() => {
    setTimeout(() => {
      document.getElementById("receiver_number_input")?.focus();
    }, 500);
  }, [openLocateModal]);
  useEffect(() => {
    const find = languages.find((item) => {
      return item.code === window.location.pathname.split("/")[1];
    });
    if (find) {
      cookies.set("langlogo", find.flagCode);
      setUpdatedFlag(find.flagCode);
    }
    // eslint-disable-next-line
  }, [window.location.pathname, cookies]);
  useEffect(() => {
    setShowLogo(updatedFlag);
    // eslint-disable-next-line
  }, [cookies, window.location.pathname]);
  const handleCancel = () => {
    setIsModalOpen(false);
    document.body.style.overflow = "unset";
  };
  //  disable language for temp
  const showModal = () => {
    setIsModalOpen(true);
    document.body.style.overflow = "hidden";
  };
  //  disable language for temp
  const handleChangeLanguage = (lang, logo) => {
    i18n.changeLanguage(lang);
    if (window.location.pathname.split("/")[2]) {
      navigate(`/${lang}/${window.location.pathname.split("/")[2]}`);
    } else {
      navigate(`/${lang}`);
    }
    setIsModalOpen(false);
    document.body.style.overflow = "unset";
    setLang(lang);
    cookies.set("lang", lang, {
      path: "/",
      sameSite: true,
    });
    cookies.set("langlogo", logo, {
      path: "/",
      sameSite: true,
    });
  };
  useEffect(() => {
    setMessage(t("SMS_DEFAULT_TEXT"));
  }, [openLocateModal]);

  useEffect(() => {
    const noScroll =
      unsubscribeModalOpen ||
      updatePaymentModalOpen ||
      openLocateModal ||
      settingUpdateModal;
    document.body.classList.toggle("no-scroll", noScroll);
    return () => document.body.classList.remove("no-scroll");
  }, [
    unsubscribeModalOpen,
    updatePaymentModalOpen,
    openLocateModal,
    settingUpdateModal,
  ]);

  const updateLang = async () => {
    const result = await callUpdateUserLanguage({
      language: lang,
    });
  };
  useEffect(() => {
    lang && updateLang();
  }, [lang]);

  return (
    details && (
      <div className="dashnoard-main-section">
        <Modal
          open={updatePaymentModalOpen}
          centered
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          closable={false}
          className={`${step === 1 ? "update__payment_wrap" : "reactivate__payment_wrap"
            } `}
        >
          {step === 1 ? (
            <UpdatePaymentModal
              setStep={setStep}
              handleNavigateUnsubscribe={handleNavigateUnsubscribe}
            />
          ) : (
            step === 2 && (
              <ReactivePaymentModal handlePaymentUpdate={handlePaymentUpdate} />
            )
          )}
        </Modal>
        <UnsubscribeModal
          unsubscribeModalOpen={unsubscribeModalOpen}
          handleCloseUnsubscribe={handleCloseUnsubscribe}
          handleUnsubscribe={handleUnsubscribe}
          successPopup={unsubscribeSuccessModal}
          unsubscribeResult={unsubscribeResult}
        />
        <div className="container">
          <div className="dashboard-header">
            <div className="main-logo-img">
              <img src={mainLogo} alt="" />
            </div>
            {/* disable language for temp  */}
            <div className="header-lang-dropdown">
              <li>
                {showLogo && (
                  <div onClick={showModal}>
                    <ReactCountryFlag
                      countryCode={showLogo}
                      svg
                    // onClick={(e) => e.preventDefault()}
                    />
                  </div>
                )}
              </li>
            </div>
            <div className="hum-burger-menu">
              <Dropdown
                menu={{ items }}
                trigger={["click"]}
                placement={lang === "ar" || lang === "he" ? "bottomLeft" : "bottomRight"}
                overlayClassName={`dashboard-header-menu-mobile ${renderRTLClass()}`}
              >
                <span onClick={(e) => e.preventDefault()}>
                  <Space>
                    <HiMenu />
                  </Space>
                </span>
              </Dropdown>
            </div>
          </div>
          <div className="dashboard-find-number dashboard-box">
            <label className="mb-1">
              <span className="dot"></span>
              <span>{t("DASH_FIND_NUMBER_LABEL")}</span>
            </label>
            <div className="dashboard-locate">
              <PhoneInput
                country={countryCodeinput || "ca"}
                onChange={(value, country) => {
                  handleSetNumber(
                    value,
                    country,
                    setDialCode,
                    setNumberValidate,
                    setCountryCode,
                    setPhoneNumber,
                    setFinalFormattedNumber,
                    phoneNumber
                  );
                }}
                placeholder={t("BANNER_INPUT")}
                onEnterKeyPress={handleOpenLocateModal}
                countryCodeEditable={false}
                autoFormat={false}
                enableSearch={true}
                searchStyle={{
                  width: "100%",
                  height: "35px",
                  borderRadius: "7px",
                  margin: "0px",
                  padding: "0px; !important",
                }}
                disableSearchIcon={true}
                searchPlaceholder={t("SEARCH")}
                searchNotFound={t("NO_MATCHED")}
              />
              <Input
                ref={inputRef}
                className={`desktop-input-nd desk ${renderRTL ? "input_ltr" : null
                  } `}
                onChange={(event) => {
                  setNumberError("");
                  handleSetPhoneNumber(
                    event,
                    setPhoneNumber,
                    setNumberValidate,
                    setCountryCode,
                    countryCodeinput,
                    setDialCode,
                    setFinalFormattedNumber,
                    inputRef
                  );
                }}
                placeholder={t("BANNER_INPUT")}
                maxLength="20"
                type="text"
                inputMode="tel"
                value={phoneNumber}
                id="phone_input"
                onPressEnter={handleOpenLocateModal}
              />
              <div className="input-suffix" onClick={handleOpenLocateModal}>
                <AiOutlineSearch
                  style={{
                    fontSize: 16,
                    color: "#1677ff",
                  }}
                />
              </div>
            </div>
            {numberError && (
              <p style={{ margin: "0", color: "red" }}>{numberError}</p>
            )}
            {numberValidate && (
              <p style={{ margin: "0", color: "red" }}>{numberValidate}</p>
            )}
            {resultError && (
              <p style={{ margin: "0", color: "red" }}>{resultError}</p>
            )}
            <p style={{ color: "#868686" }}>{t("DASHBOARD_DAILY_LIMIT")}</p>
          </div>
          {locationListData &&
            locationListData.data &&
            locationListData.data.locations.length > 0 ? (
            <div className="dashboard-history dashboard-box">
              <div className="dashboard-history-title">
                <label className="mb-1">
                  <span className="dot"></span>
                  <span>{t("DASH_HISTORY")}</span>
                </label>
              </div>
              <div className="dashboard-history-data">
                <div className="items-scrollable">
                  {locationListData &&
                    locationListData.data &&
                    locationListData.data.locations.map((item, index) => {
                      return (
                        <div
                          key={index}
                          onClick={() =>
                            item.geo &&
                            handleChangeMap(
                              item.geo.lat,
                              item.geo.long,
                              item.geo.formatted
                            )
                          }
                          className={item.geo ? "addresspointer" : null}
                        >
                          <span className="text-dark">{item.sender}</span>
                          <span className="address">
                            {item.geo && item.geo.formatted}
                          </span>
                          <span className="status">
                            <span
                              className={`badge status-0 ${item.status ? "located" : "pending"
                                }`}
                            >
                              {item.status
                                ? t("SMS_STATUS_LOCATED")
                                : t("SMS_STATUS_PENDING")}
                            </span>
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          ) : null}
          <div className="dashboard-map dashboard-box">
            <div
              className="map-container"
              style={{
                height: "500px",
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              {isLoaded && (
                <GoogleMap
                  zoom={latLong.length > 1 ? 15 : 10}
                  center={center}
                  mapContainerStyle={{ height: "100%", width: "100%" }}
                  options={mapOptions}
                >
                  {latLong.map((marker, index) => (
                    <MarkerF
                      key={index}
                      position={marker.latlng}
                      onClick={() => setInFowindowOpen(marker.latlng.lat)}
                    >
                      {inFowindowOpen === marker.latlng.lat && (
                        <InfoWindowF>
                          <p>{marker.address}</p>
                        </InfoWindowF>
                      )}
                    </MarkerF>
                  ))}
                </GoogleMap>
              )}
            </div>
          </div>
        </div>
        <Modal
          open={openLocateModal}
          onCancel={handleCloseLocateModal}
          centered
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          className={`${renderRTLClass()} location_modal dsh_location_modal_popup`}
        >
          <div className="login-modal-section">
            <div
              className="login-modal-title"
              style={{
                fontSize: 20,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <span className="span">{t("LOCATING")}</span>
              <p>{formatedNumber.replace("++", "+")}</p>
            </div>
            <Spin spinning={sendLocation.isLoading} size="large">
              <div className="wrap-top-modal">
                <div className="body-dash-modal">
                  <div className="dashboard-find-number dashboard-box">
                    <label className="mb-1">
                      <span className="dot"></span>
                      <span>{t("RECEIVE_NUMBER_TEXT")}</span>
                    </label>
                    <div className="dashboard-locate">
                      <PhoneInput
                        country={locatingPopupCountryCodeinput || "ca"}
                        onChange={(value, country) =>
                          handleSetNumber(
                            value,
                            country,
                            setLocatingPopupDialCode,
                            setNumberValidate,
                            setLocatingPopupCountryCode,
                            setReceiverNumber,
                            setLocatingPopupPhoneNumber,
                            setFinalFormattedNumber,
                            locatingPopupPhoneNumber
                          )
                        }
                        className="dsh_locating_popup"
                        countryCodeEditable={false}
                        onEnterKeyPress={handleSendMessage}
                        autoFormat={false}
                        inputProps={{
                          id: "receiver_number_input",
                          disabled: true,
                        }}
                        enableSearch={true}
                        searchStyle={{
                          width: "100%",
                          height: "35px",
                          borderRadius: "7px",
                          margin: "0px",
                          padding: "0px; !important",
                        }}
                        disableSearchIcon={true}
                        searchPlaceholder={t("SEARCH")}
                        searchNotFound={t("NO_MATCHED")}
                      />
                      <Input
                        ref={inputRef}
                        className={`desktop-input-nd desk dsh_locating_input  ${renderRTL ? "input_ltr" : null
                          } `}
                        onChange={(event) =>
                          handleSetPhoneNumber(
                            event,
                            setLocatingPopupPhoneNumber,
                            setPopNumberError,
                            setLocatingPopupCountryCode,
                            locatingPopupCountryCodeinput,
                            setLocatingPopupDialCode,
                            setFinalFormattedNumber,
                            // setLocatingNumberError,
                            inputRef
                          )
                        }
                        placeholder={t("BANNER_INPUT_MOBILE")}
                        maxLength="20"
                        type="text"
                        inputMode="tel"
                        value={locatingPopupPhoneNumber}
                        onPressEnter={() =>
                          handleSendMessage(
                            locatingPopupPhoneNumber,
                            locatingPopupCountryCodeinput,
                            locatingPopupDialCode,
                            phoneNumber,
                            dialCode,
                            setLocatingPopupPhoneNumber,
                            setPhoneNumber
                          )
                        }
                      />
                      {popNumberError && (
                        <p style={{ margin: " 0", color: "red" }}>
                          {popNumberError}
                        </p>
                      )}
                    </div>
                  </div>
                  <Form>
                    <div className="dashboard-message dashboard-box">
                      <label className="mb-1">
                        <span className="dot"></span>
                        <span>{t("MESSAGE")}</span>
                      </label>
                      <div className="dashboard-locate">
                        <textarea
                          defaultValue={t("SMS_DEFAULT_TEXT")}
                          placeholder={t("SMS_DEFAULT_TEXT")}
                          onChange={handleChangeMessage}
                          value={message}
                          ref={phoneRef}
                        />
                        <div className="erroe_char-length">
                          {messageError && (
                            <p className="message_error">{messageError}</p>
                          )}
                          <p className="max_char_length">
                            {messageLength} / 250
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="submit-btn-modal">
                      <button
                        onClick={() =>
                          handleSendMessage(
                            locatingPopupPhoneNumber,
                            locatingPopupCountryCodeinput,
                            locatingPopupDialCode,
                            phoneNumber,
                            dialCode,
                            setLocatingPopupPhoneNumber,
                            setPhoneNumber
                          )
                        }
                        className="hl_cta_wrap"
                        type="submit"
                        disabled={sendLocation.isLoading}
                      >
                        {t("SENT_MESSAGE_CONTINUE")}
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </Spin>
          </div>
        </Modal>
        <Modal
          open={settingUpdateModal}
          centered
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          closable={false}
          className={renderRTLClass()}
        >
          <div className="login-modal-section">
            <div className="login-modal-title" style={{ fontSize: 20 }}>
              {t("SETTINGS_BILLING_INFO_LABEL")}
            </div>
            <div>
              <div
                className="setting-find-number dashboard-box"
                style={{ marginTop: "30px" }}
              >
                <Form>
                  <div className="billing-form">
                    <div className="input-fx">
                      <div className="f-name-input">
                        <label>{t("SETTINGS_FIRST_NAME_LABEL")}*</label>
                        <Input
                          placeholder={t("SETTINGS_FIRST_NAME_LABEL")}
                          name="first_name"
                          onChange={handleChangeDetails}
                          value={details.first_name}
                        />
                        {error.first_name && (
                          <span style={{ color: "red" }}>
                            {error.first_name}
                          </span>
                        )}
                      </div>
                      <div className="l-name-input">
                        <label>{t("SETTINGS_LAST_NAME_LABEL")}*</label>
                        <Input
                          placeholder={t("LAST_NAME_lABEL")}
                          name="last_name"
                          onChange={handleChangeDetails}
                          value={details.last_name}
                        />
                        {error.last_name && (
                          <span style={{ color: "red" }}>
                            {error.last_name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="add-inp">
                      <label>{t("SETTINGS_ADDRESS_LABEL")}*</label>
                      <Input
                        placeholder={`1 ${t("MAIN_STREET")}`}
                        name="address"
                        onChange={handleChangeDetails}
                        value={details.address}
                      />
                      {error.address && (
                        <span style={{ color: "red" }}>{error.address}</span>
                      )}
                    </div>
                    <div className="input-fx">
                      <div className="f-name-input">
                        <label>{t("SETTINGS_COUNTRY_LABEL")}*</label>
                        {isMobile ? (
                          <select
                            showSearch
                            name="country"
                            value={details.country}
                            onChange={handleChangeCountry}
                          >
                            {countryOptions?.map((item) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                          </select>
                        ) : (
                          <Select
                            defaultValue={details.country}
                            name="country"
                            onChange={handleChangeCountry}
                            value={details.country}
                            options={countryOptions}
                            showSearch
                            filterOption={(input, option) => {
                              if (option && option.label) {
                                const optionLabel = option.label
                                  .toString()
                                  .toLowerCase();
                                const inputValue = input.toLowerCase();
                                return optionLabel.indexOf(inputValue) !== -1;
                              }
                              return false;
                            }}
                          />
                        )}
                        {error.country && (
                          <span style={{ color: "red" }}>{error.country}</span>
                        )}
                      </div>
                      <div className="l-name-input">
                        <label>{t("SETTINGS_CITY_LABEL")}*</label>
                        <Input
                          placeholder="Barcelona"
                          name="city"
                          onChange={handleChangeDetails}
                          value={details.city}
                        />
                        {error.city && (
                          <span style={{ color: "red" }}>{error.city}</span>
                        )}
                      </div>
                    </div>
                    <div className="input-fx">
                      <div className="f-name-input">
                        <label>{label}*</label>
                        <Input
                          placeholder="0123"
                          name="zipcode"
                          onChange={handleChangeDetails}
                          value={details.zipcode}
                        />
                        {error.zipcode && (
                          <span style={{ color: "red" }}>{error.zipcode}</span>
                        )}
                      </div>
                    </div>
                    <div className="save-billing-btn">
                      <button
                        onClick={handleUpdateDetails}
                        className="hl_cta_wrap"
                        disabled={updateSetting.isLoading}
                      >
                        {t("SETTINGS_SAVE_BUTTON")}
                      </button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          open={isModalOpen}
          onCancel={handleCancel}
          okButtonProps={{ style: { display: "none" } }}
          cancelButtonProps={{ style: { display: "none" } }}
          className={renderRTLClass()}
        >
          <div className="modal-container">
            <div className="modal-title">
              <p>{t("LANGUAGE")}</p>
            </div>
            <div className="modal-languages">
              <div className="df-modal-language">
                {languages.map((item, index) => (
                  <div
                    className={`lang ${lang === item.code && "active"}`}
                    onClick={() =>
                      handleChangeLanguage(item.code, item.flagCode)
                    }
                    key={index}
                  >
                    <div className="flag">
                      <ReactCountryFlag
                        countryCode={item.flagCode}
                        svg
                        style={{
                          width: "22px",
                          height: "22px",
                        }}
                      />
                    </div>
                    <span>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  );
};

export default DashboardComponent;
