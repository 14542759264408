import React, { useEffect, useRef, useState } from "react";
import DashboardComponent from "./dashboard";
import { sendLocation } from "../../redux/slice/sendLocationSlice";
import { connect } from "react-redux";
import { locationList } from "../../redux/slice/locationListSlice";
import Notify from "../common/Notify/notify";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useTranslation } from "react-i18next";
import { updateSetting } from "../../redux/slice/updateSettingSlice";
import { getSendDetails } from "../../redux/slice/getSendDetailsSlice";
import { useSearchParams } from "react-router-dom";
import { unsubscribe } from "../../redux/slice/unsubscribeSlice";
import { authenticate } from "../../redux/slice/authenticateSlice";
import { updateUserLanguage } from "../../redux/slice/updateUserLanguageSlice";

const Dashboard = (props) => {
  const {
    callsendLocation,
    getLocationList,
    locationListData,
    callupdateSetting,
    authenticate,
    getSendDetails,
    ipData,
    updateSetting,
    sendLocation,
    callunsubscribe,
    unsubscribeResult,
    getAuthenticate,
    callUpdateUserLanguage,
  } = props;
  const [countryCode, setCountryCode] = useState(+1);
  const [receiverCountryCode, setReceiverCountryCode] = useState(+1);
  const [receiverCountry, setReceiverCountry] = useState(countryCode);
  const [number, setNumber] = useState("");
  const [receiverNumber, setReceiverNumber] = useState("");
  const { t } = useTranslation();
  const [message, setMessage] = useState(t("SMS_DEFAULT_TEXT"));
  const [messageLength, setMessageLength] = useState(message.length);
  const [numberError, setNumberError] = useState("");
  const [openLocateModal, setOpenLocateModal] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [loading, isLoading] = useState(false);
  const [searchparams] = useSearchParams();
  const searchNumber = Object.fromEntries([...searchparams]);
  const phoneRef = useRef(null);
  useEffect(() => {
    if (searchNumber.D && searchNumber.n) {
      setReceiverCountryCode("+" + searchNumber.D);
      setNumber(searchNumber.D + searchNumber.n);
      setCountryCode("+" + searchNumber.D);
    }
  }, []);
  useEffect(() => {
    getLocationList();
  }, [getLocationList]);
  const [countryShortName, setCountryShortName] = useState("ca");
  const [countryCodeinput, setCountryCodeinput] = useState("ca");
  const handleOnNumberChange = (value, country) => {
    setCountryCode(`+${country.dialCode}`);
    setNumber(value);
    setCountryCodeinput(country.countryCode.toUpperCase());
    setCountryShortName(country.countryCode.toUpperCase());
    setNumberError("");
  };
  function cutParagraph(text) {
    const limit = 250;
    if (text.length <= limit) {
      return text;
    }
    return text.slice(0, limit);
  }
  const handleChangeMessage = (event) => {
    const { value } = event.target;
    const trimmedValue =
      typeof value === "string" ? value.replace(/^\s+/g, "") : value;
    setMessage(cutParagraph(trimmedValue));
    setMessageLength(cutParagraph(trimmedValue).length);
    if (trimmedValue === "") {
      setMessageError(t("SMS_MESSAGE_ERROR"));
    } else {
      setMessageError("");
    }
  };

  const [popNumberError, setPopNumberError] = useState("");
  const handleOnReceiverNumberChange = (value, country) => {
    setPopNumberError("");
    setReceiverCountry(country.countryCode.toUpperCase());
    setReceiverCountryCode(`+${country.dialCode}`);
    setReceiverNumber(value);
    setCountryCodeinput(country.countryCode.toUpperCase());
  };

  const [resultError, setResultError] = useState("");
  const handleSendMessage = async (
    locatingPopupPhoneNumber,
    locatingPopupCountryCodeinput,
    countryCodeReciver,
    formatedNumber,
    dialCode,
    setLocatingPopupPhoneNumber,
    setPhoneNumber
  ) => {
    let checkValue;
    let trimmedNumber;
    if (
      dialCode === "91" &&
      formatedNumber.length === 14 &&
      formatedNumber.startsWith(dialCode)
    ) {
      // Remove the dial code (91) from the phone number
      checkValue = formatedNumber.slice(dialCode.length).toString();
    } else {
      trimmedNumber = formatedNumber.trim();

      if (trimmedNumber.startsWith(dialCode)) {
        trimmedNumber = trimmedNumber.substring(dialCode.length).trim();
        if (
          trimmedNumber
            .replace(" ", "")
            .replace("-", "")
            .replace("(", "")
            .replace(")", "").length < 10
        ) {
          if (dialCode.length <= 2) {
            trimmedNumber = dialCode + trimmedNumber;
          }
        }
      }
    }
    if (!loading) {
      if (locatingPopupPhoneNumber) {
        if (
          isValidPhoneNumber(
            locatingPopupPhoneNumber,
            `${
              locatingPopupCountryCodeinput
                ? locatingPopupCountryCodeinput.toString()?.toUpperCase()
                : countryCodeinput?.toUpperCase()
            }`
          )
        ) {
          if (!messageError) {
            const sender_mobile_number = checkValue
              ? checkValue
              : `+${trimmedNumber}`.toString();
            const receiver_mobile_number = `+${locatingPopupPhoneNumber}`
              .toString()
              .replace(locatingPopupCountryCodeinput, "");
            const senderNumber = "+" + dialCode === 1 ? "+1" : "+" + dialCode;
            const data = {
              sender_country_code: senderNumber.replace("++", "+"),
              sender_mobile_number: sender_mobile_number?.replace("+", ""),
              receiver_country_code: countryCodeReciver.includes("+")
                ? countryCodeReciver
                : `+${countryCodeReciver}`,
              receiver_mobile_number: locatingPopupPhoneNumber
                .toString()
                .replace(locatingPopupCountryCodeinput, ""),
              message: message,
            };
            isLoading(true);
            const result = await callsendLocation(data);
            if (result.type === "sendLocation/fulfilled") {
              Notify("success", t("SEND_LOCATION"), "");
              setOpenLocateModal(false);
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
              setLocatingPopupPhoneNumber("");
              setPhoneNumber("");
              getLocationList();
              isLoading(false);
              setReceiverNumber(locatingPopupCountryCodeinput);
              setNumber(dialCode);
              setMessage(t("SMS_DEFAULT_TEXT"));
              setMessageLength(t("SMS_DEFAULT_TEXT").length);
            } else if (result.type === "sendLocation/rejected") {
              setResultError(result.error.message);
              setOpenLocateModal(false);
              isLoading(false);
            }
          }
        } else {
          setPopNumberError(t("ERROR_VALID_NUMBER_DASHBOARD"));
        }
      } else {
        setPopNumberError(t("ERROR_ENTER_NUMBER_DASHBOARD"));
      }
    }
  };

  return (
    <DashboardComponent
      handleOnNumberChange={handleOnNumberChange}
      handleSendMessage={handleSendMessage}
      handleOnReceiverNumberChange={handleOnReceiverNumberChange}
      locationListData={locationListData.locationListData}
      number={number}
      openLocateModal={openLocateModal}
      setOpenLocateModal={setOpenLocateModal}
      handleChangeMessage={handleChangeMessage}
      setNumberError={setNumberError}
      numberError={numberError}
      countryShortName={countryShortName}
      setCountryCodeinput={setCountryCodeinput}
      popNumberError={popNumberError}
      resultError={resultError}
      messageError={messageError}
      loading={loading}
      authenticate={authenticate}
      callupdateSetting={callupdateSetting}
      receiverNumber={receiverNumber}
      updateSetting={updateSetting}
      getSendDetails={getSendDetails}
      ipData={ipData}
      sendLocation={sendLocation}
      callunsubscribe={callunsubscribe}
      unsubscribeResult={unsubscribeResult.unsubscribeData}
      getAuthenticate={getAuthenticate}
      message={message}
      messageLength={messageLength}
      phoneRef={phoneRef}
      setReceiverNumber={setReceiverNumber}
      receiverCountryCode={receiverCountryCode}
      setMessageError={setMessageError}
      setPopNumberError={setPopNumberError}
      setMessage={setMessage}
      setMessageLength={setMessageLength}
      callUpdateUserLanguage={callUpdateUserLanguage}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    locationListData: state.locationList,
    authenticate: state.authenticate,
    updateSetting: state.updateSetting,
    ipData: state.getIPIfy.getIPIfyData,
    sendLocation: state.sendLocation,
    unsubscribeResult: state.unsubscribe,
    updateUserLanguage: state.updateUserLanguage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    callsendLocation: (data) => dispatch(sendLocation(data)),
    getLocationList: () => dispatch(locationList()),
    callupdateSetting: (data) => dispatch(updateSetting(data)),
    getSendDetails: (data) => dispatch(getSendDetails(data)),
    callunsubscribe: (data) => dispatch(unsubscribe(data)),
    getAuthenticate: () => dispatch(authenticate()),
    callUpdateUserLanguage: (data) => dispatch(updateUserLanguage(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
